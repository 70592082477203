body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.iconContainer {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.iconContainer img {
  cursor: pointer;
}

main {
  height: 100vh !important;
  overflow-y: hidden;
  overflow-x: hidden !important;
}

main img,
main video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}